import './stylesheets/main.scss';
import './stylesheets/home.scss';
import { gsap } from "gsap";

document.addEventListener("DOMContentLoaded", function() {

gsap.to(".overlay", { 
    opacity: 0, 
    width: '0%', 
    duration: .8
  });


gsap.from(".bottom", {
    opacity: 0, 
    y: 100, 
    duration: 1
  });

  gsap.from(".top", {
    opacity: 0, 
    y: -100, 
    duration: 1
  });

  gsap.from(".left", { 
    opacity: 0, 
    x: -100, 
    duration: 1
  });

});
  

